import React, { Component } from "react"

export class WishList extends Component {
  render() {
    const { places } = this.props;

    return (
      <div>
        <ul>
          { places && places.map(place => (
            <li>{place}</li>
          ))}
        </ul>
      </div>
    )
  }
}
export default WishList
