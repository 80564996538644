import React from "react"
import get from "lodash/get"
import Helmet from "react-helmet"
import Template from "../components/layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import '../components/index.css'
import WishList from "../components/wishList"
import SEO from "../components/seo"
import AboutMeSection from "../components/aboutMeSection.js"
// gatsby auto reads the index.js as the homepage.
class indexPage extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const posts = get(this, "props.data.allContentfulBlogPost.edges")

    return (
      <Template>
        <Helmet title={siteTitle} />
        <div className="indexpage">
          <div className="section index-container image-one" style={{ paddingTop: '13px', height:'500px' }}>
          </div>
          <AboutMeSection />
          <div className="section index-container image-two" style={{ paddingTop: '13px', height:'500px' }}>
          </div>
        </div>
      </Template>
    )
  }
  // render() {
  //     const siteTitle = get(this, "props.data.site.siteMetadata.title")
  //     const posts = get(this, "props.data.allContentfulBlogPost.edges")
  //     return (
  //       <Template>
  //         <Helmet title={siteTitle} />
  //         <div className="indexpage">
  //           <div className="container">
  //             <div className="row">
  //               <div className="col-lg-9">
  //                 <div className="row blogRow">
  //                   {posts.map(({ node }) => {
  //                   return (
  //                     <div className="col-lg-6">
  //                       <article className="blog-listing" key={node.slug}>
  //                         <Link to={node.slug} className="entry-read-more">
  //                           <h2 className="entry-title">
  //                             <Link to={node.slug}>{node.title}</Link>
  //                           </h2>
  //                         </Link>
  //                         <div className="entry-media">
  //                           <img src={node.heroImage.fluid.src}/>
  //                         </div>
  //                         <p className="entry-content">
  //                           {node.description.description}
  //                         </p>
  //                       </article>
  //                     </div>
  //                   )
  //                 })}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </Template>
  //     )
  //   }
}

export default indexPage

// export const pageQuery = graphql`
//   query HomeQuery {
//     site {
//       siteMetadata {
//         title
//         description
//       }
//     }
//     allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
//       edges {
//         node {
//           title
//           slug
//           publishDate(formatString: "MMMM Do, YYYY")
//           body {
//             body
//           }
//         }
//       }
//     }
//   }
// `

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          description {
            description
          }
          heroImage {
            fluid {
              src
            }
          }
          body {
            body
          }
        }
      }
    }
  }
`
