import React, { Component } from "react"
import Link from "gatsby-link"
import Picture from "../images/aboutme3.jpg"
import "./aboutme.css"

export class AboutMeSection extends Component {
  render() {
    return (
      <div class="container">
        <div class="row aboutme">
          <div class="col-sm">
            <img src={Picture} class="img-fluid" alt="Responsive image" style={{ height: '250px', width: '250px', borderRadius: '50%'}}/>
          </div>
          <div class="col-sm">
            <h2> About Me 👋</h2>
            <p> Hi, I'm Tanya. </p>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutMeSection
